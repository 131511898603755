
import { Options, Vue } from "vue-class-component";
import CustomSlider from "@/components/CustomSlider.vue";

@Options({
  components: {
    CustomSlider,
  },
  data() {
    return {
      items: [
        {
          path: "/guinong/brand",
          name: "品牌文化",
        },
        {
          path: "/guinong/company",
          name: "归农文艺",
        },
      ],
    };
  },
})
export default class Guinong extends Vue {}
